<template>
  <div class="nav">
 <el-menu  router :default-active="activeIndex" 
 class="el-menu-demo navList" mode="horizontal" 
  active-text-color="#0388DD"
   background-color="#25374b"
  text-color="#fff">
   <el-menu-item index="1" class="menuItem1" v-if="activeIndex === 'homeData'">全部产品分类</el-menu-item>
  <el-submenu index="1" v-else>
    <template slot="title">全部产品分类</template>
    <el-submenu  v-for="(item,index) in MenuList" :key="index" :index="'1-'+index" class="elSubMenuTitle">
      <template slot="title">
        <div class="menuTitle">{{item.title}}</div>
      </template>
      <el-menu-item  class="elMenuItem">
            <div class="submenuCon">
        <div class="title">{{item.title}} 系列</div>
       <div class="subMenu displayRow">
          <div class="cateLeft">
            <el-button type="primary" class="CateItem" 
             v-for="(j,k) in item.childrenClassify" 
             :key="k"
             @click="toGoods(item.classifyId,j.classifyId)"
            >{{j.title}}</el-button>
          </div>
          <div class="goodRight">
          
            <div class="area right2">
               <div class="rightTitle">
                <i class="el-icon-search icon-title"></i>
                <span>热销商品</span>
              </div>
              <ul class="displayRow top2Con">
                <li class="top2Item" v-for="(e,r) in item.Top2List" :key="r" @click="toDetails(e.goodsId)">
                  <img class="top2Img" :src="e.defaultImageUrl ? e.defaultImageUrl : e.imageUrl" alt="">
                    <el-link class="top2Name" :underline="false">{{e.title}}</el-link>
                  <p class="top2Price priceSty">￥{{e.minPrice ? e.minPrice : '--'}}</p>
                </li>
              </ul>
            </div>
          </div>
       </div>
    </div>
      </el-menu-item>
    </el-submenu>
  </el-submenu>
  <el-menu-item index="HomeData">首页</el-menu-item>
  <el-menu-item index="GoodsData">全部商品</el-menu-item>
</el-menu>


  </div>
</template>

<script>
import {getClassify} from '@/assets/js/api.js'
import { getGoodsList } from "@/assets/js/api.js";
export default {
    components:{
       
    },
data(){
    return{
        activeIndex:'',
        menuFlag:false,
        MenuList:[]
    }
},
watch:{
 $route () {
      this.setCurrentRoute()
    }

},
methods:{
  setCurrentRoute(){
    this.activeIndex = this.$route.name;
  },
    toGoods(parentId,childId){
    this.$router.push({
      path:'/GoodsData',
      query:{
        parentId:parentId,
        childId:childId,
        v:Math.random()
      }
    })
  },
    getData(goodsClassifyId,index){
                 let data = {
                    goodsClassifyId:goodsClassifyId,
                    pageNo:'1',
                    };
                getGoodsList(data).then(res =>{
                    if(res.data.code === 200){
                    let Top2List = res.data.result.pageList.slice(0,6);
                    this.MenuList[index].Top2List = Top2List;
                    }
                    }) 
            },
              //获取商品详情
  toDetails(classifyId){
      let url= this.$router.resolve({
    name: "DetailsData",  
       query:{
        classifyId:classifyId
      }
  });

   window.open(url.href, "_blank");
  }
},
created(){
  this.setCurrentRoute();
    //获取全部分类
    getClassify().then((res) => {
      if (res.data.code === 200) {
        this.MenuList = res.data.result.slice(0,7);
         this.MenuList.forEach((item,index)=>{
          this.$set(item,'Top2List',[]);
          this.getData(item.classifyId,index);
        })
      }
    });
}
}
</script>

<style scoped>
.nav{
background-color:#25374b;
}
.navList{
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    /* background-color: #545c64!important; */
}
.all{
    width:150px;
    background-color: #0388DD!important;
    text-align: center;
}
.menuPosition{
    position: absolute;
}
.submenuCon{
  width:740px;
  box-sizing: border-box;
}
.title{
  border-bottom: 1px solid #eee;
  padding:5px;
  color:#000;
}
.subMenu{
  padding-top:10px;
}
.subMenu{
  align-items: inherit;
}
.CateItem{
  background-color: #0388DD;
  color:#fff;
  width:100px;
  padding:8px 0;
  text-align: center;
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
}
.cateLeft{
  flex:1.2;
}
.goodRight{
  flex:2;
}
.area{
  margin-bottom: 20px;
  color:#666;
  font-size: 14px;
}
.rightTitle{
  border-bottom: 1px dashed #eee;
  padding-bottom:5px;
}
.icon-title{
  margin-right: 5px;
}
.top1Con,.top2Con{
  flex-wrap: wrap;
}
.top1Item{
  margin-right: 10px;
  margin-top:5px;
}
.top2Item{
   margin-top:5px; 
   margin-right: 30px;
   cursor: pointer;
}
.top2Img{
  width:103px;
  height: 103px;
  border-radius: 10px;
}
.top2Name{
    width:103px;
    display:block;
    overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
font-size: 12px;
padding:5px;

}
.top2Price{
  text-align: center;
}
.elMenuItem{
    height: 100%!important;
    background-color: #fff!important;
}
</style>